<template>
  <div id="app">
    <nav class="ui labeled icon top menu">
      <router-link class="item" to="/">
        <i class="home icon" />
        首頁
      </router-link>
      <router-link class="item" to="/about">
        <i class="info icon"></i>
        關於
      </router-link>
      <router-link class="item" to="/man">
        <i class="stack overflow icon"></i>
        曼陀羅
      </router-link>
      <router-link class="item" to="/paper">
        <i class="paper plane outline icon"></i>
        摺紙
      </router-link>
      <router-link class="item" to="/shuriken">
        <i class="crosshairs icon"></i>
        紙飛鏢</router-link>
      <router-link class="item" to="/dye">
        <i class="eye dropper icon"></i>
        植物染
      </router-link>
      <router-link class="item" to="/maker">
        <i class="hand point down outline icon"></i>
        手工藝
      </router-link>
      <!--   add  router-link  to   maker    here ... -->
    </nav>
    <router-view/>
  </div>
</template>

<script type="text/javascript">

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: '歡迎',
    // all titles will be injected into this template
    titleTemplate: '%s | 小太陽作品集'
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  background-color: #42b983 !important;
}

.ui.menu {
  border: none !important;
}

img {
  position: relative;
  top: 0;
  max-width: 15vw;
  border-radius: 50px;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

img:hover {
  top: -2em;
}

.ui.good.button {
  width: 23%;
  max-width: 420px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: .6em !important;
  margin: .4em !important;
}

.ui.good.button {
  font-size: 24px;
  margin: 0 !important;
}

@media screen and (max-width: 900px) and (min-width: 701px) {
  img {
    max-width:  22vw;
  }
}

@media screen and (max-width: 700px) {
  img {
    max-width: 45vw;
  }

  .flex {
    display: flex;
  }

  .flex-center {
    align-items: center;
    justify-content: center;
  }
}

</style>
