<template>
  <div class="hello">
    <h1>小太陽作品集</h1>
    <div class="ui segment container flex flex-center">
      <router-link class="ui good button" to="/man">
        <img src="../assets/m0.jpg" />
        <br/>
      曼陀羅</router-link>
      <router-link class="ui good button" to="/paper">
        <img src="../assets/paper/p0.jpeg" /><br/>
      摺紙</router-link>
      <router-link class="ui good button" to="/shuriken">
        <img src="../assets/shuriken/s0.png" /><br/>
      紙飛鏢</router-link>
      <router-link class="ui good button" to="/dye">
        <img src="../assets/dye/d1.jpg" /><br/>
        植物染
      </router-link>
      <router-link class="ui good button" to="/dye">
        <img src="../assets/maker/catapult.png" /><br/>
        手工藝
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  metaInfo: {
    title: '作品集'
  },
  data () {
    return {
    }
  },
  mounted () {
  }
}

</script>

<style type="text/css" scoped>

img {
  max-width: 100%;
  border-radius: 50px;
}

</style>
